import React, { useEffect, useState } from "react"

import EasterEggStyles from "./easter-egg.module.css"
import classNames from "classnames"

const EasterEgg = () => {
    const [icon, setIcon] = useState(0)
    const [color, setColor] = useState("#444")
    const icons = ["", "", ""]

    useEffect(() => {
        const nextIcon = () => {
            let nextIcon = icon + 1
            if (nextIcon >= icons.length) nextIcon = 0
            setIcon(nextIcon)
        }
        const interval = setInterval(() => nextIcon(), 750)
        return () => clearInterval(interval)
        // eslint-disable-next-line
    }, [icons])

    useEffect(() => {
        if (icon !== 0) return
        let nextColor = ""
        while (nextColor.length < 6) {
            nextColor += Math.random()
                .toString(16)
                .substr(-6)
                .substr(-1)
        }
        setColor(`#${nextColor}`)
    }, [icon])

    return (
        <div className={EasterEggStyles.block}>
            <i
                className={classNames("lavita-icon", { [EasterEggStyles.secondIcon]: icon === 1 })}
                style={{ color: color }}
            >
                {icons[icon]}
            </i>
        </div>
    )
}

export default EasterEgg
