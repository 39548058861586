import Center100vhStyles from "./center-100vh.module.css"
import React from "react"
import { useDimensions } from "../../shareact/hooks/use-dimensions"

const Center100vh = props => {
    const { height } = useDimensions()
    const { height: headerHeight } = useDimensions("header")

    return (
        <div style={{ minHeight: height - headerHeight }} className={Center100vhStyles.wrapper}>
            <div>{props.children}</div>
        </div>
    )
}

export default Center100vh
