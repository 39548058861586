import Center100vh from "../components/universal/center-100vh"
import Footer from "../components/layout/footer"
import FourOFour from "../components/404/404"
import Header from "../components/layout/header"
import Overlay from "../shareact/components/overlay"
import React from "react"
import Seo from "../components/universal/seo"
import Sitemap from "../components/sitemap"
import t from "../locales/de/translations.json"

const PageNotFound = () => {
    return (
        <>
            <Header />
            <main>
                <Center100vh>
                    <FourOFour />
                </Center100vh>
            </main>
            <Overlay />
            <Footer />
            <Sitemap hidden />
        </>
    )
}

export default PageNotFound

export const Head = ({ location }) => {
    return (
        <Seo
            title={t["page_not_found_title"]}
            description={t["page_not_found_description"]}
            pathname={location.pathname}
        />
    )
}
