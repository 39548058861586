import EasterEgg from "../../shareact/components/404/easter-egg"
import FourOFourStyles from "./404.module.css"
import React from "react"
import Spacer from "../universal/spacer"
import Text from "../universal/text"
import t from "../../locales/de/translations.json"
import { useState } from "react"

const FourOFour = () => {
    const [easterEgg, setEeasterEgg] = useState(false)

    return (
        <div className={FourOFourStyles.wrapper}>
            <section className={FourOFourStyles.block}>
                <div>
                    <button
                        type="button"
                        onClick={() => setEeasterEgg(!easterEgg)}
                        className={FourOFourStyles.icon}
                        title="easter egg"
                    >
                        {!easterEgg ? (
                            <>
                                <i className="lavita-icon"></i>
                                <span>404</span>
                            </>
                        ) : (
                            <EasterEgg />
                        )}
                    </button>
                </div>
                <Spacer size={{ desktop: "medium", tablet: "small", mobile: "small" }} />
                <Text center source={t["page_not_found_headline"]} />
                <Spacer size={{ desktop: "medium", tablet: "small", mobile: "small" }} />
            </section>
        </div>
    )
}

export default FourOFour
